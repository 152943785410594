$breakpoints: (
        lg: 'only screen and (min-width: 1200px)',
        md: 'only screen and (min-width: 768px)',
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint: #{$breakpoint}.";
  }
}

@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('/assets/fonts/SF-Pro-Display-Medium.otf');
}

@font-face {
  font-family: 'SF-Pro-Text-Medium';
  src: url('/assets/fonts/SF-Pro-Text-Medium.ttf');
}

$font-sans: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
$font-calli: Baskerville, Georgia, "Liberation Serif", "Kaiti SC", STKaiti, "AR PL UKai CN", "AR PL UKai HK", "AR PL UKai TW", "AR PL UKai TW MBE", "AR PL KaitiM GB", KaiTi, KaiTi_GB2312, DFKai-SB, "TW\-Kai", serif;
$font-serif: Georgia, "Nimbus Roman No9 L", "Songti SC", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;




// ---------------------------------------------------------------------------- //
$color-white: #FFFFFF; // 白色
$color-black: #222222; // 黑色
$color-primary: #C5BC98;
$color-system-bg: #F2F2F2;
$color-text-black: #333333;
$color-text-gray: #7A7C86;
$color-light: #F7CB8A;
$color-border: #D9D9D9;
$color-axis: #CEC3BE;




@mixin text-h1 {
  font-family: 'SF-Pro-Display-Medium', sans-serif;
  font-size: 21px;
  line-height: 25px;
  user-select: none;
}
@mixin text-h2 {
  font-family: 'SF-Pro-Display-Medium', sans-serif;
  font-size: 20px;
  line-height: 24px;
  user-select: none;
}
@mixin text-h3 {
  font-family: 'SF-Pro-Display-Medium', sans-serif;
  font-size: 14px;
  line-height: 22px;
  user-select: none;
}
@mixin text-p {
  font-family: 'SF-Pro-Text-Medium', sans-serif;
  font-size: 14px;
  line-height: 18px;
  user-select: none;
}
@mixin text-caption {
  font-family: 'SF-Pro-Text-Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
  letter-spacing: -0.01;
}

@mixin multiline {
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;
}

@mixin ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @include multiline
}



body, page {
  // font-family: $font-sans;

}
html {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
  user-select: none;
  flex-shrink: 0;
  max-width: unset;
}

p {
  white-space: pre-line;
  margin: 0;
}

span {
  display: inline-block;
  white-space: nowrap;
}



// Chrome 滚动条样式
/*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.15);
  border-radius: 20px;
  background-clip: content-box;
  border: 1px solid transparent;
}*/






.layout {
  position: relative;
  width: 74px;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.bg {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: $color-white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.content {
  z-index: 2;
  position: relative;
  width: 68px;
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &-img {
    z-index: 2;
    border-radius: 1.5px;
  }
  &-tools {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    &-icon {
      width: 12px;
      color: $color-text-black;
    }
  }
  &-tips {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &-text {
      @include text-caption;
      color: $color-text-gray;
    }
  }
}

.mini-button {
  width: 24px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(255,221,171,0.75);
  // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
}

.temp {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  &-text {
    @include text-p;
    color: $color-text-black;
    user-select: auto;
  }
}